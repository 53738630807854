import Layout from "../../layouts"
import React, {useState, useContext, useEffect, useRef} from "react"

import {CfpSearch} from "../../components/cfp/cfpSearch"
import {Box,} from "@mui/material"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {CfpheaderCards} from "../../components/cfp/muidb/cfpheadercards"
import Cfptb from "../../components/cfp/cfptb"
import {cfpColumns} from "../../components/utils/cfpcolumns"
import Hider from "../../components/utils/Hider"
import {CfpDropdown, DownloadList} from "../../components/cfp/cfpDropdown"
import {gUtils} from "../../components/utils/gutils"
import {CfpMsg} from "../../components/cfp/cfpMsg";
import {navigate} from "gatsby";
import {gConfig} from "../../chatai/utils/config";
export default function VCards({location}) {
    const [openHead, setOpenHead] = useState(false)
    const {state = {}} = location
    const {
        gf,
        gCfpCards,
        gCfp,
        gIotCfp,
        setModalTitle,
        setCustomModalOpen,
        setModalContent,
        gMemberDevice,
        gCompanyEmployee
    } = useContext(GlobalStateContext)

    const [openDropDown, setOpenDropDown] = useState(false)
    const [listDown, setListDown] = useState([])
    const ref = useRef(null)
    const [tbData, setTbData] = useState([])
    const [saveTbData, setSaveTbData] = useState([])
    const {title} = state || '';
    const [listTempMembers,setListTempMembers]=useState([])
    const [dropdownPosition, setDropdownPosition] = useState({left: 0, top: 0})
    useEffect(() => {
        if (gCfpCards.temDeviceCards.length>0&&listTempMembers.length>0){
            listTempMembers.map(item=>{
                if (gCfpCards.temDeviceCards.some(device=>device.cardID===item.cardID)){
                    gCfpCards.updateCards(item)
                }
            })
        }
    }, [gCfpCards.temDeviceCards,listTempMembers])
    useEffect(() => {
        if (!title){
            navigate('/')
        }else {
            if (!openHead) {
                gIotCfp.sendCmd(state.uuid,gConfig.cmdCode.cardSetFinish)

            }
            gCfpCards.setTemDeviceCards([])

            return () => {
                gIotCfp.sendCmd(state.uuid,gConfig.cmdCode.cardSetFinish)

            }
        }

    }, [openHead])




    useEffect(() => {
        let result = gCfpCards.allCards.filter(item => item.uuids.some(ite => ite === state.uuid))
        result = result.map(item => {
            if (item.memberID) {
                item.groupName = gMemberDevice.list.reduce((groupName, device) => {
                    if (device.uuids.some(i => i === item.memberID)) {
                        groupName.push(device.name);
                    }
                    return groupName;
                }, []);
            }
            return item

        })

        setTbData(result);
        setSaveTbData(result);


    }, [gCfpCards.allCards,gMemberDevice.list]);
    useEffect(() => {
        gIotCfp.subIotDevices()
        return ()=>{
            gIotCfp.cancelSubscriptions();

        }
    }, []);

    const showError = async () => {
        setModalTitle('')
        setModalContent(
            <CfpMsg msg={'有効なカード名およびIDを入力してください。いずれも空欄では登録できません。'}
                    onClick={() => {
                        setCustomModalOpen(false)
                    }}

            />
        )
        setCustomModalOpen(true)
    }
    return (
        <Layout location={location}>
            <Box sx={gf.styles.v}>
                <CfpSearch
                    callSearch={(e) => {



                        if (e) {


                            setTbData(
                                gUtils.findSearchList(
                                    e,
                                    saveTbData,

                                    gCfp.cfpDevices
                                )
                            )
                        } else {
                            setTbData(saveTbData)
                        }
                    }}
                />

                <Hider show={openHead}>
                    <CfpheaderCards
                        style={{marginTop: "0px"}}
                        setClose={() => {
                            setOpenHead(false)
                        }}

                        csvData={(data) => {

                            console.log("csv添加数据", data)
                            if (gCfp.getWifiState(state.uuid)){

                                let list = []
                                let isCardIDExist = true
                                data.forEach((item) => {
                                    let data = {}
                                    if (item.id || item.ID||item.cardID) {
                                        data.cardID = item.id || item.ID||item.cardID
                                        data.name = item.カード名||item.name||''
                                        data.cardType=item.cardType||'02'
                                        if (item.ユーザー) {
                                            let member = gCompanyEmployee.employees.Items.find(it => it.employeeName === item.ユーザー)
                                            if (member){
                                                data.memberID=member.subUUID
                                                setListTempMembers(prevState => [...prevState,data])
                                            }

                                        }

                                        list.push(data)
                                    }else {
                                        isCardIDExist = false
                                    }
                                })

                                if (isCardIDExist === true) {
                                    if (list.length > 0) {
                                        gIotCfp.sendCmds(state.uuid,gConfig.cmdCode.cardPut,list.map(item=>{
                                            return gUtils.toCardInfo(item.cardID, item.cardType, item.name)
                                        }))

                                    }
                                } else {
                                    console.log("当前数据", "erro")
                                    showError()
                                }

                            }



                        }}
                        data={gf.fonts.fontCfpCards}
                        goSet={(isChoose) => {
                            gIotCfp.sendCmd(state.uuid,isChoose?gConfig.cmdCode.cardSet:gConfig.cmdCode.cardSetFinish)

                        }}
                        mdatas={gCfpCards.temDeviceCards}
                        addCard={(d) => {
                            console.log("添加数据", d)

                            gCfpCards.updateCards(d)

                        }}
                    />
                </Hider>
                <Cfptb
                    callAdd={() => {
                        setOpenHead(true)
                    }}
                    isAdd={!openHead}
                    data={tbData}
                    path={"/cfp/carddetails"}
                    isBind={false}
                    isBack={true}
                    text={`${title}${gf.fonts.fontCfpCards.listTitle}`}
                    columns={cfpColumns.cardColumns({
                        gCompanyEmployee:gCompanyEmployee,
                        click: (isGroup,value, e) => {
                            console.log("cardsのテーブル")
                            const rect = e.currentTarget.getBoundingClientRect()
                            console.log("rect", rect)
                            setDropdownPosition({left: rect.left, top: rect.top})
                            setOpenDropDown(true)

                            if (!isGroup){

                                setListDown(gUtils.uuidsToNames(value, gCfp.cfpDevices))
                            }else {
                                setListDown(value)
                            }
                        },
                        ref: ref,
                        listDevices: gCfp.cfpDevices
                    })}
                    callDelData={(items) => {

                       gIotCfp.sendCmds(state.uuid,gConfig.cmdCode.cardDelete,items.map(item=>{
                           return gUtils.toCardInfo(item.cardID, item.cardType, item.name)
                       }))

                    }}
                />

                <CfpDropdown
                    open={openDropDown}
                    closeOpen={() => {
                        setOpenDropDown(false)
                    }}
                    position={dropdownPosition}
                    viewRef={ref}
                >
                    <DownloadList list={listDown}/>
                </CfpDropdown>
            </Box>
        </Layout>
    )
}
